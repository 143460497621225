<template>
  <v-snackbar
    v-if="toastOptions"
    v-model="showToast"
    v-bind="toastOptions"
    class="global-snackbar"
    data-test="global-snackbar"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="toastOptions.message" />

    <template v-slot:action="{ attrs }">
      <v-btn v-if="toastOptions.closable" icon v-bind="attrs" @click="showToast = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'

const showToast = ref(false)

const store = useStore()
const toastOptions = computed(() => store.state.toastOptions)

watch(toastOptions, () => {
  if (!showToast.value) {
    showToast.value = true
    return
  }

  showToast.value = false
  setTimeout(() => {
    showToast.value = true
  }, 100)
})
</script>

<style lang="scss" scoped>
.global-snackbar {
  z-index: 10000 !important;
}
</style>
